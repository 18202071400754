import ShadowWrapper from "components/ShadowWrapper"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const SmallImage = ({ asset, crop, hotspot, caption, className }) => {
  // console.log("🦄 ~ file: SmallImage.jsx:9 ~ SmallImage ~ asset:", asset)
  return (
    <figure
      css={{
        maxWidth: 840,
        margin: "50px auto",
        padding: "0 25px",
        [theme.mq.mobile]: {
          margin: "30px auto",
        },
      }}
      className={className}
    >
      <ShadowWrapper shadow={theme.shadows.outerBlur}>
        <SanityImage
          asset={asset}
          crop={crop}
          hotspot={hotspot}
          width={1228}
          sizes="(max-width: 1278px) calc(100vw - 50px), 1228px"
          css={{ width: "100%" }}
          alt=""
        />
      </ShadowWrapper>
      {caption && (
        <figcaption
          css={{
            ...theme.p3,
            opacity: 0.6,
            marginTop: "0.75em",
          }}
        >
          {caption}
        </figcaption>
      )}
    </figure>
  )
}

export default SmallImage

SmallImage.propTypes = {
  asset: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      _ref: PropTypes.string.isRequired,
    }),
  ]),
  crop: PropTypes.object,
  hotspot: PropTypes.object,
  caption: PropTypes.string,
  className: PropTypes.string,
}

export const query = graphql`
  fragment SmallImageBlock on SanitySmallImage {
    ...ImageWithPreview
    caption
  }
`
