import ArticleTile from "components/ArticleTile"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const RelatedArticles = ({ title, articles }) => {
  const settings =
    articles.length === 3
      ? {
          largeTiles: false,
          // alignment: "start",
          alignment: "stretch",
          columns: 3,
          showCategory: true,
        }
      : {
          largeTiles: true,
          alignment: "stretch",
          columns: 2,
          showCategory: false,
        }

  return (
    <div
      css={{
        background: theme.colors.accentBackground,
        // padding: "80px 25px",
        padding: "48px 25px",
        [theme.mq.mobile]: {
          padding: "30px 25px",
        },
      }}
    >
      <div css={{ maxWidth: 1228, margin: "0 auto" }}>
        <h2 css={{ ...theme.h1, textAlign: "center" }}>{title}</h2>

        <div
          css={{
            display: "grid",
            gridTemplateColumns: `repeat(${settings.columns}, 1fr)`,
            gap: 20,
            justifyContent: "center",
            alignItems: settings.alignment,
            marginTop: 50,
            [theme.mq.max(700)]: {
              gridTemplateColumns: "1fr",
              maxWidth: 300,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        >
          {articles.map(({ _id, ...props }) => (
            <ArticleTile
              key={_id}
              showCategory={settings.showCategory}
              large={settings.largeTiles}
              {...props}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RelatedArticles

RelatedArticles.propTypes = {
  title: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      ...ArticleTile.propTypes,
      _id: PropTypes.string.isRequired,
      className: undefined,
      showCategory: undefined,
    }).isRequired
  ).isRequired,
}

export const query = graphql`
  fragment RelatedArticlesBlock on SanityRelatedArticles {
    title
    articles {
      ...ArticleTile
    }
  }
`
