import AutoLink from "components/AutoLink"
import ColorSwatch from "components/ColorSwatch"
import ShadowWrapper from "components/ShadowWrapper"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const ArticleTile = ({
  title,
  slug,
  externalLink,
  coverImage,
  category: { name: category, section },
  showCategory,
  large,
  className,
}) => (
  <AutoLink
    to={externalLink || slug.current}
    target={externalLink && "_blank"}
    css={{
      textDecoration: "none",
      color: theme.colors.primaryText,
      "&:hover svg": {
        stroke: theme.colors.core.darkPurple,
      },
    }}
    className={className}
  >
    <div
      css={{
        background: theme.colors.floatingContainerBackground,
        boxShadow: theme.shadows.tight,
        borderRadius: 4,
        padding: 10,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        position: "relative",
      }}
    >
      <div
        css={{
          marginBottom: "auto",
        }}
      >
        <h3
          css={{
            ...theme.h3,
            padding: 10,
            // paddingBottom: 0,
            marginBottom: externalLink ? 0 : 24,
            maxWidth: 450,
          }}
        >
          {title}
        </h3>
        {externalLink && (
          <div
            css={{
              display: "inline-block",
              marginLeft: 10,
              marginBottom: 10,
              marginTop: 10,
              [theme.mq.tablet]: {
                marginTop: 0,
                // marginBottom: 0,
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke={theme.colors.extendedUI.grey1}
              width={16}
              height={16}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </div>
        )}
      </div>

      {coverImage && (
        <ShadowWrapper>
          <SanityImage
            {...coverImage}
            width={large ? 586 : 320}
            height={section.name === "Insights" ? (large ? 350 : 320) : 320}
            alt=""
            css={{
              width: "100%",
              "&[data-lqip='true']": {
                minHeight:
                  section.name === "Insights" ? (large ? 348 : 272) : 272,
              },
            }}
          />
        </ShadowWrapper>
      )}
      {showCategory && (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            ...theme.p3,
            color: theme.colors.secondaryText,
            marginTop: 20,
            borderTop: `solid 1px ${theme.colors.extendedUI.lightGrey}`,
          }}
        >
          <Appendage css={{ display: "flex", alignItems: "center" }}>
            <ColorSwatch
              color={section.color}
              size={20}
              css={{ marginRight: 8 }}
            />{" "}
            {section.name}
          </Appendage>
          <Appendage>{category}</Appendage>
        </div>
      )}
    </div>
  </AutoLink>
)

export default ArticleTile

ArticleTile.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.shape({ current: PropTypes.string.isRequired }),
  coverImage: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }),
  externalLink: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    section: PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.shape({
        hex: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showCategory: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
}

export const Appendage = (props) => (
  <div
    css={{
      background: theme.colors.floatingContainerBackground,
      // boxShadow: theme.shadows.tight,
      // borderRadius: 4,
      padding: "0.777em 0.666em",
    }}
    {...props}
  />
)

export const query = graphql`
  fragment ArticleTile on SanityArticle {
    _id
    title
    externalLink
    slug {
      current
    }
    coverImage {
      ...ImageWithPreview
    }
    category {
      name
      section {
        name
        color {
          hex
        }
      }
    }
  }
`
