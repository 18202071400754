import { useEffect, useRef } from "react"
import { nanoid } from "nanoid"
import useSharedState from "hooks/useSharedState"

/**
 * Maintains shared state across multiple components, returning `true` for the
 * first-encountered instance that is still mounted.
 *
 * @param {String} id The ID of the singleton; used to de-dupe across all instances.
 * @returns {Boolean} Indication that this component should render content
 */
const useSingleton = (id) => {
  const ref = useRef(nanoid())
  const [instances, setInstances] = useSharedState(id, [ref.current])

  useEffect(() => {
    setInstances((prev) =>
      prev.includes(ref.current) ? prev : [...prev, ref.current]
    )

    return () => {
      setInstances((prev) =>
        prev.filter(
          (instance) =>
            // Note: we can safely disable exhaustive-deps for the next line
            // because `ref.current` is not pointing to a React or DOM node
            // eslint-disable-next-line react-hooks/exhaustive-deps
            instance !== ref.current
        )
      )
    }
  }, [setInstances])

  return instances[0] === ref.current
}

export default useSingleton
