import React from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"

const ColorSwatch = ({ color, size = "1em", className }) => (
  <div
    css={{
      width: size,
      height: size,
      background: color?.hex || "#eee",
      borderRadius: "50%",
      boxShadow: theme.shadows.inset,
    }}
    className={className}
  />
)

export default ColorSwatch

ColorSwatch.propTypes = {
  color: PropTypes.shape({
    hex: PropTypes.string.isRequired,
  }),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
}
