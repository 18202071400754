import React from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"

/**
 * This is intended to wrap an image with the markup and styles
 * needed to display an inset shadow with rounded corners.
 *
 */
const ShadowWrapper = ({
  shadow = theme.shadows.inset,
  className,
  children,
}) => (
  <div
    css={theme.expandQueries({
      position: "relative",
      borderRadius: [4, 2],
      "img, video": {
        borderRadius: [4, 2],
      },
      ":after": {
        content: "''",
        boxShadow: shadow,
        borderRadius: [4, 2],
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2,
        pointerEvents: "none",
      },
    })}
    className={className}
  >
    {children}
  </div>
)

export default ShadowWrapper

ShadowWrapper.propTypes = {
  shadow: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
