import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage, { parseImageRef } from "gatsby-plugin-sanity-image"
import theme from "styles/theme"
import ShadowWrapper from "components/ShadowWrapper"

const containerWidth = 1228

const BigImages = ({ images, className }) => {
  const ratios = images.map(({ asset }) => {
    const { width, height } = parseImageRef(asset._id || asset._ref).dimensions
    return width / height
  })
  const avg = ratios.reduce((sum, r) => sum + r, 0) / ratios.length

  return (
    <div
      css={{
        display: "flex",
        alignItems: "flex-start",
        maxWidth: containerWidth + 50,
        margin: "50px auto",
        padding: "0 25px",
        [theme.mq.mobile]: {
          flexDirection: "column",
          margin: "30px auto",
        },
      }}
      className={className}
    >
      {images.map((image, index) => (
        <figure
          key={image._key}
          css={{
            margin: 0,
            width: (ratios[index] / avg) * 50 + "%",
            flex: `1 1 ${(ratios[index] / avg) * 50 + "%"}`,
            minWidth: "40.717%",
            marginRight: 20,
            ":last-child": { marginRight: 0 },
            [theme.mq.mobile]: {
              flex: "1 1 auto",
              width: 80 * (1 - (1 - ratios[index]) / 2) + "%",
              maxWidth: "90%",
              alignSelf: ratios[index] < 1 ? "flex-end" : "flex-start",
              marginRight: 0,
              ":last-child": { marginTop: 20 },
              "& + &": {
                alignSelf: ratios[index] < 1 ? "flex-start" : "flex-end",
              },
            },
          }}
        >
          <ShadowWrapper shadow={theme.shadows.image}>
            <SanityImage
              {...image}
              width={Math.round(
                ratios[index] < 1
                  ? Math.max(
                      0.40717 * containerWidth,
                      ((ratios[index] / avg) * containerWidth) / 2
                    )
                  : Math.min(
                      (1 - 0.40717) * containerWidth,
                      ((ratios[index] / avg) * containerWidth) / 2
                    )
              )}
              css={{ width: "100%" }}
              alt=""
              loading="lazy"
            />
          </ShadowWrapper>
          {image.caption && (
            <figcaption
              css={{
                ...theme.p3,
                opacity: 0.6,
                marginTop: "0.75em",
                float: index > 0 && "right",
                [theme.mq.mobile]: {
                  float: "none",
                },
              }}
            >
              {image.caption}
            </figcaption>
          )}
        </figure>
      ))}
    </div>
  )
}

export default BigImages

BigImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      asset: PropTypes.oneOfType([
        PropTypes.shape({
          _ref: PropTypes.string.isRequired,
        }).isRequired,
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }).isRequired,
      ]).isRequired,
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  fragment BigImagesBlock on SanityBigImages {
    images {
      _key
      ...ImageWithPreview
    }
  }
`
