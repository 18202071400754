import { useEffect, useState } from "react"

export const createSharedState = (initialValue) => {
  let _updaters = []
  let _value = initialValue

  const useSingleton = () => {
    const [value, update] = useState(_value)

    useEffect(() => {
      _updaters.push(update)
      return () => (_updaters = _updaters.filter((el) => el !== update))
    }, [])

    return value
  }

  const updateSingleton = (updateValue) => {
    _value =
      typeof updateValue === "function" ? updateValue(_value) : updateValue
    _updaters.forEach((cb) => cb(_value))
  }

  return [useSingleton, updateSingleton]
}

const singletons = {}

/**
 * Behaves like `useState`, but maintains consistent state across
 * all component instances using the same ID.
 *
 * @param {String} id Unique ID for this singleton object
 * @param {*} initialValue Optional initial state; works just like `useState`
 * @returns {[*, function]} Returns [value, setter] tuple just like `useState`
 */
const useSharedState = (id, initialValue) => {
  if (!singletons[id]) {
    singletons[id] = createSharedState(initialValue)
  }

  const [hook, setter] = singletons[id]
  return [hook(), setter]
}

export default useSharedState
